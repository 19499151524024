import About from "../sections/About";
import Experience from "../sections/Experience";



function MyComponent() {
    return (
        <main className="about-us">
            <section className="about-hero"></section>
            <About />
            <Experience />
        </main>
    );
}

export default MyComponent;