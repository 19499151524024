import './App.scss';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Karamapuram from './pages/Karamapuram';
import Menubar from './components/Menubar';
import { Button, Image } from 'react-bootstrap';
import Mettupalayam from './pages/Mettupalayam';
import Muthampatty from './pages/Muttampatty';
import Valapady from './pages/Valapady';
import About from './pages/About';
import Contact from './pages/Contact';

function App() {
  return (
    <div className="App">
      <header className="App-header position-absolute w-100">
        <Menubar />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/karamapuram" element={<Karamapuram />} />
        <Route path="/valapady" element={<Valapady />} />
        <Route path="/muthampatty" element={<Muthampatty />} />
        <Route path="/mettupalayam" element={<Mettupalayam />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <footer className='App-footer py-5 py-md-0'>
        <div className='footer-left py-md-5'>
          <div className='footer-logo text-center py-5'>
            <Image src='/images/DMCLogo.png' />
          </div>
          <div className='quick-links d-flex flex-column py-2 px-3'>
            <Link className='' to='/'>HOME</Link>
            <Link className='' to='/about-us'>ABOUT US</Link>
            <Link className='' to='/'>COMPLETED PROJECTS</Link>
            <Link className='' to='/'>ONGOING PROJECTS</Link>
            <Link className='' to='/contact-us'>BOOK SITE VISIT</Link>
          </div>
        </div>
        <div className='footer-right px-md-5'>
          <div className='reach-us py-5 proxima-regular'>
            <h3 className='mb-3 poppins-semibold fs-6 px-2'>REACH US</h3>
            <address className='px-2'>
              Capital Towers, 3rd Floor,<br />
              96/4, Permanur Road,<br />
              Next to PRN Hospitals,<br />
              Salem, Tamilnadu - 636007<br />
            </address>
            <div className='social-links position-absolute'>
              <i className='bi bi-facebook'></i>
              <i className='bi bi-linkedin'></i>
              <i className='bi bi-twitter'></i>
              <i className='bi bi-youtube'></i>
              <i className='bi bi-instagram'></i>
            </div>
          </div>
          <div className='get-in-touch py-5 d-flex flex-column proxima-regular'>
            <h3 className='mb-3 poppins-semibold fs-6'>GET IN TOUCH</h3>
            <a href='mailto:hello@dmcgardens.com' className='mb-3'>hello@DMCGardens.com</a>
            <a href="tel:09842791427">+91 98427 91427</a>
          </div>
        </div>
      </footer>
      <Button className='floating position-fixed' href="https://wa.me/9842791427">
        <i className='bi bi-whatsapp'></i>
      </Button>
    </div>
  );
}

export default App;
